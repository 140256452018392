@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply font-roboto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-mundial;
    font-weight: 600;
  }
}

body {
  min-width: 1440px;
}
.icon-gradient-large,
.icon-gradient {
  background: linear-gradient(to top, var(--tw-gradient-stops));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-gradient {
  background-size: 100% 85%;
}

.underline-pink {
  text-decoration: underline;
  text-decoration-color: #ff0066;
}

.underline-orange {
  text-decoration: underline;
  text-decoration-color: #ff8539;
}
